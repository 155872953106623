// src/components/SignOutButton.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase-config";
import Button from "@mui/material/Button";
import { setCurrentTab } from "../slices/tabSlice";

export const LogOutButton = ({ variant, sx = {} }) => {
  const navigate = useNavigate();

  const handleLogOut = async () => {
    try {
      await auth.signOut();
      console.log("Signed out successfully");
      localStorage.clear();
      setCurrentTab("Home");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };

  return (
    <Button variant={variant} onClick={handleLogOut} sx={sx}>
      Sign Out
    </Button>
  );
};

export const LogInButton = ({ variant, sx = {} }) => {
  const navigate = useNavigate();
  return (
    <Button variant={variant} onClick={() => navigate("/login")} sx={sx}>
      Log In
    </Button>
  );
};

export const SignUpButton = ({ variant, sx = {} }) => {
  const navigate = useNavigate();

  return (
    <Button
      variant={variant}
      onClick={() => navigate("/register")}
      sx={sx}
    >
      Sign Up
    </Button>
  );
};
