import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { db } from "../../../config/firebase-config";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  limit,
  query,
} from "firebase/firestore";
import {
  getItemById,
  getUriByStates,
} from "../../../shared/Library/ItemLibrary";

// Fetch room by room ID
export const fetchGameById = createAsyncThunk(
  "rooms/fetchGameById",
  async ({ id }) => {
    try {
      console.log("Fetching game");
      const docRef = doc(db, "rooms", `${id}`);
      const res = await getDoc(docRef);

      const itemConfigs = res.data().itemConfigs;

      const updatedItemConfigs = {};

      res.data().itemOrder.map((itemName) => {
        
        const item = itemConfigs[itemName];
        const color = item.color;
        
        
        // Change all the opened item back to their default image
        const defaultUri = item.state === "opened" ? getUriByStates(itemName, color, "default") : item.uri;

        // Replaced the description with Custom Message, if exist
        const description =
          item.description === "Custom Message"
            ? item.customDescription
            : item.description;
        updatedItemConfigs[itemName] = {
          ...item,
          uri: defaultUri,
          description,
        };
      });

      const newData = {
        ...res.data(),
        itemConfigs: updatedItemConfigs,
      };

      const room = {
        id: res.id,
        data: newData,
      };

      console.log("Fetched room for gameplay: ", room);


      return room;
    } catch (err) {
      console.log("Error fetching room by id: ", err);
    }
  },
);

// Fetch randomes rooms
export const fetchRandomRooms = createAsyncThunk(
  "rooms/fetchRandomRooms",
  async ({ count }) => {
    try {
      console.log("Fetching random rooms");
      const q = query(collection(db, "rooms"), limit(count));

      const snapshot = await getDocs(q);

      const rooms = snapshot.docs.map((doc) => ({
        id: doc.id,
        imageUrl: doc.data().imageUrl,
      }));
      return rooms;
    } catch (err) {
      console.log("Error fetching random rooms: ", err);
    }
  },
);

const initialState = {
  inventory: [],
  items: {},
  itemOrder: [],
  // isEscaped: false,
  time: 0,
  selected: null,
  recommended: [],
  background: "",
  creator: "",
};

export const gameplaySlice = createSlice({
  name: "gameplay",
  initialState,
  reducers: {
    addToInventory: (state, action) => {
      const { itemId, itemUri } = action.payload;
      // When an Inventory item is clicked, it is added to inventory list.
      state.inventory = [...state.inventory, { id: itemId, uri: itemUri }];
      state.items[itemId].visible = false
      console.log(
        `Adding ${itemId} into inventory. Result: ${state.inventory}`,
      );
    },
    displayHidingItem: (state, action) => {
      const { itemId, openUri } = action.payload;
      if (openUri) {
        state.items[itemId].uri = openUri;
        state.items[itemId].state = "opened";
      }
      const hidingItem = state.items[itemId].itemToHide;
      // console.log(`${hidingItem} is hidden in ${itemId}`);
      if (hidingItem) {
        state.items[hidingItem].isHidden = false;
      }
      console.log("Change state to opened, ", state.items[itemId].uri);
    },
    unlockItem: (state, action) => {
      const { itemId, itemToUnlock } = action.payload;
      console.log("unlocking item: ", itemId)
      // if (itemId === "Door") {
      //   state.isEscaped = true;
      // } else {

      // Update item state to "opened" and look for the URI corresponding to the current color
      state.items[itemId].state = "opened";
      const color = state.items[itemId].color;
      const openUri = getItemById(itemId).variants[color]["opened"];
      // Keep the same URI if the item doesn't have a open state image
      if (openUri) {
        state.items[itemId].uri = openUri;
      }

      // Add the current item to inventory if it can be added to inventory
      const addToInventory = state.items[itemId].isAddableToInventory;
      console.log("Unlocked item can be added to inventory, ", addToInventory);
      if (addToInventory) {
        state.inventory = [...state.inventory, { id: itemId, uri: state.items[itemId].uri }];
        state.items[itemId].visible = false
      }


      // Unlock the item
      state.items[itemId].isItemLocked = false;

      // Remove the used item from inventory
      state.inventory = state.inventory.filter(
        (item) => item.id !== itemToUnlock,
      );

      // Reveal the item if it is storing something
      const hidingItem = state.items[itemId].itemToHide;
      // console.log(`${hidingItem} is hidden in ${itemId}]`);
      if (hidingItem) {
        state.items[hidingItem].isHidden = false;
      }
    },
    hideItem: (state, action) => {
      console.log("Hiding item");
      const { itemId } = action.payload;
      state.items[itemId].visible = false;
    },
    showItem: (state, action) => {
      const { itemId } = action.payload;
      state.items[itemId].visible = true;
    },
    storeEscapeTime: (state, action) => {
      console.log("Store; ", action.payload);
      state.time = action.payload;
    },
    selectedTool: (state, action) => {
      console.log("Selected inventory: ", action.payload);
      state.selected = action.payload;
    },
    clearSelection: (state) => {
      console.log("Dropped tools:");
      state.selected = null;
    },
    resetGameplay: (state) => {
      console.log("Resetting gameplay...");
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGameById.fulfilled, (state, action) => {
        const { id, data } = action.payload;
        const newState = {
          ...initialState,
          creator: data.creator,
          roomName: data.roomName,
          introduction: data.introduction,
          timeLimit: data.timeLimit,

          items: data.itemConfigs,
          itemOrder: data.itemOrder,
          background: data.background,
          itemCounter: data.itemCounter,
        };

        console.log("States loaded to game: ", newState);
        return newState;
      })
      .addCase(fetchRandomRooms.fulfilled, (state, action) => {
        state.recommended = action.payload;
        console.log("Recommendations:", state.recommended);
      });
  },
});

export const {
  addToInventory,
  removeFromInventory,
  displayHidingItem,
  unlockItem,
  hideItem,
  showItem,
  storeEscapeTime,
  selectedTool,
  clearSelection,
  resetGameplay,
} = gameplaySlice.actions;

export const selectInventories = (state) => state.gameplay.inventory;
export const selectItems = (state) => state.gameplay.items;

export default gameplaySlice.reducer;
