import "@fontsource/poppins/400.css"; // Regular
import "@fontsource/poppins/500.css"; // Medium
import "@fontsource/poppins/600.css"; // SemiBold

import { createTheme } from "@mui/material/styles";


const theme = createTheme({
  palette: {
    primary: {
      main: "#B31217",
    },
    secondary: {
      main: "#202020",
    },
    background: {
      default: "#1B1B1B",
    },
    text: {
      primary: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Poppins", "Siller", "sans-serif"].join(","),
    fontWeight: "bold",
    button: {
      textTransform: "none",
      fontSize: "0.8rem",
      padding: "30px 30px",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "0.8rem",
    },
    h5: {
      fontSize: "13px",
      // fontWeight: "bold",
    },
    body1: {},
    caption: {
      fontSize: "1rem",
      fontWeight: "normal",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          color: "#000",
          backgroundColor: "#fff",
          minWidth: "auto",
          height: "auto",
          overflow: 'visible',
          lineHeight: 1.5,
          '&:hover': {
            backgroundColor: "#B31217", // Change background color on hover
            color: "#fff", // Change text color on hover
          },
          '&:disabled': {
            color: "grey"
          }
        },
        contained: {
          backgroundColor: "#333333",
          color: "#fff",
          // padding: "5px 25px",
        },
        outlined: {
          backgroundColor: "#333333",
          color: "#fff",
          border: "1px solid #e0e0e0",
          // padding: "5px 25px",
        },
        containedPrimary: {
          backgroundColor: "#fff",
          color: "#000",
        },
        text: {
          borderRadius: 16,
          padding: "5px 20px",
          color: "#000",
          backgroundColor: "#fff",
          minWidth: "auto",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#333333",
          borderRadius: 10,
          width: "300px",
          height: "200px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#fff",
          fontSize: "13px"

        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: "#fff",
          '& .MuiFormControlLabel-label': {
            fontSize: '13px', // Set the desired font size here
            textAlign: 'left', // Align text to the left
            display: 'block', // Ensure it takes full width for alignment
          },

        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        multiline: {
          "& input": {
            color: "#fff", // Change the text color to white
            fontSize: "13px",
          },
        },
        root: {
          // Override the TextField styles here
          "& .MuiOutlinedInput-root": {
            // Input field styles
            "& fieldset": {
              // color: "#fff",
              borderColor: "#8c8c8c",
              background: "rgba(255, 255, 255, 0.13)",
              // backgroundColor: "#333",
            },
            "& input": {
              color: "#fff", // Change the text color to white
              fontSize: "16px",
              height: "16px", // Set the desired height
              // padding: "10px", // Adjust padding to maintain spacing
              letterSpacing: "2px"
            },
            // "&:hover fieldset": {
            //   borderColor: "#333",
            // },
            // "&.Mui-focused fieldset": {
            //   borderColor: "#333",
            // },
          },
          // Change label styles
          "& .MuiInputLabel-root": {
            color: "#fff", // Change the label color
            // fontSize: "13px", // Change the font size
          },
          "& .MuiInputLabel-shrink": {
            color: "#ccc", // Change the label color when shrunk
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "12px", // Change the font size of the helper text
          color: "#ff4081", // Change the color if needed
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          // Styles for the root element of the Select component
          backgroundColor: "#f5f5f5",
          color: "#000",
          height: "30px",
          "&:hover": {
            backgroundColor: "#e0e0e0",
          },
          "&.Mui-focused": {
            backgroundColor: "#d0d0d0",
          },
        },
        icon: {
          // Styles for the dropdown icon
          color: "#000",
        },
        paper: {
          // Styles for the dropdown menu
          backgroundColor: "#fff",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
          "& .MuiMenuItem-root": {
            // Styles for the MenuItem elements
            padding: "8px 16px",
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
            "&.Mui-selected": {
              backgroundColor: "#e0e0e0",
              color: "#333",
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.MuiCheckbox-root": {
            color: "#fff",
          },
        }
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#fff"
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          color: '#fff', // Change text color
          borderRadius: '8px', // Rounded corners
          backgroundColor: "#1B1B1B",
        },

      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          padding: "29px 40px", // Add padding around the contents
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // textAlign: "center",
          fontWeight: 700,
          fontSize: "1.5rem"
        }
      }
    }

  },
});

export default theme;
