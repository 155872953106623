// NotFound.js
import { Typography } from '@mui/material';
import React from 'react';

const NotFound = () => {
  return (
    <div style={{ height: "95vh", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", gap: "10px" }}>
      <Typography variant='h1' fontSize="30px" color="text.primary">404 - Page Not Found</Typography>
      <Typography variant='h5' color="text.primary">Oops! The page you're looking for doesn't exist.</Typography>
    </div>
  );
};

export default NotFound;
