import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { backgroundLibrary } from "../../shared/Library/BackgroundLibrary";
import { db, storage } from "../../config/firebase-config";
// import admin from 'firebase-admin'; // Ensure firebase-admin is imported for Timestamp
import {
  addDoc,
  getDocs,
  collection,
  query,
  where,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

import {
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  itemLibraryV2,
} from "../../shared/Library/ItemLibrary";

// Add new room to firestore
export const addRoomToFirestore = createAsyncThunk(
  "rooms/addRoomToFirestore",
  async ({ room }, { getState }) => {
    try {
      const state = getState();

      const summary = {
        createdAt: Math.floor(Date.now() / 1000),
        creatorId: room.creatorId,
        creator: room.creator,
        isPreviewed: state.editor.roomSettings.isPreviewed,
        isPublished: state.editor.roomSettings.isPublished && !state.editor.error,
        roomName: state.editor.roomSettings.roomName,
        timeLimit: state.editor.roomSettings.timeLimit,
        error: state.editor.error,
        avgRating: 0,
        count: 0,
      }
      const addRoomSummaryRef = await addDoc(collection(db, "summary"), summary);
      console.log("Added to summary: ", addRoomSummaryRef.id);

      const roomDetails = {
        ...room,
        roomName: state.editor.roomSettings.roomName,
        introduction: state.editor.roomSettings.introduction,
        timeLimit: state.editor.roomSettings.timeLimit,
        itemConfigs: state.editor.roomSettings.itemConfigs,
        itemOrder: state.editor.roomSettings.itemOrder,
        itemCounter: state.editor.roomSettings.itemCounter,
        isPreviewed: state.editor.roomSettings.isPreviewed,
        isPublished: state.editor.roomSettings.isPublished && !state.editor.error,
        error: state.editor.error,
        // imageUrl: "",
        // createdAt: Math.floor(Date.now() / 1000)
      };


      console.log("New room: ", room);
      // Storing room details to room
      // const addRoomRef = await addDoc(collection(db, "rooms"), roomDetails);
      await setDoc(doc(db, "rooms", addRoomSummaryRef.id), { ...roomDetails });
      console.log("Added to rooms: ", addRoomSummaryRef.id);

      const newRoom = { id: addRoomSummaryRef.id, data: { ...room } };

      console.log("Added Room: ", newRoom);
      return newRoom;
    } catch (err) {
      console.log("Error adding new room: ", err);
    }
  },
);

// Edit existing room in firestore
export const updateRoomInFirestore = createAsyncThunk(
  "rooms/updateRoomInFirestore",
  async ({ roomId, room }, { getState }) => {
    try {
      const state = getState();

      const summary = {
        createdAt: Math.floor(Date.now() / 1000),
        creatorId: room.creatorId,
        creator: room.creator,
        isPreviewed: state.editor.roomSettings.isPreviewed,
        isPublished: state.editor.roomSettings.isPublished && !state.editor.error,
        roomName: state.editor.roomSettings.roomName,
        timeLimit: state.editor.roomSettings.timeLimit,
        error: state.editor.error,
        avgRating: 0,
        count: 0,
      }
      await setDoc(doc(db, "summary", roomId), { ...summary });

      const roomDetails = {
        ...room,
        roomName: state.editor.roomSettings.roomName,
        introduction: state.editor.roomSettings.introduction,
        timeLimit: state.editor.roomSettings.timeLimit,
        itemConfigs: state.editor.roomSettings.itemConfigs,
        itemOrder: state.editor.roomSettings.itemOrder,
        itemCounter: state.editor.roomSettings.itemCounter,
        isPreviewed: state.editor.roomSettings.isPreviewed,
        isPublished: state.editor.roomSettings.isPublished && !state.editor.error,
        error: state.editor.error,
        // imageUrl: "",
        // createdAt: Math.floor(Date.now() / 1000)
      };


      // Updating settings of the rooms
      await setDoc(doc(db, "rooms", roomId), { ...roomDetails });
      // await setDoc(doc(db, "rooms", roomId), { ...roomDetails, imageUrl });
      return roomId;
    } catch (err) {
      console.error("Error updating room: ", err);
      throw err;
    }
  },
);

export const uploadScreenshotToStorage = createAsyncThunk(
  "rooms/uploadScreenshotToStorage",
  async ({ roomId, screenshot }) => {
    try {
      console.log("Uploading to storage: ")

      const storageRef = ref(storage, `images/${roomId}.webp`);
      await uploadString(storageRef, screenshot, "data_url");

      const imageUrl = await getDownloadURL(storageRef);

      const docRef = db.collection('rooms').doc(roomId);
      await docRef.update({ imageUrl: imageUrl })

    } catch (err) {
      console.err("Error uploading screenshot: ", err)
    }

  }
)

// Fetch room from firestore
export const fetchRooms = createAsyncThunk("rooms/fetchRooms", async () => {
  const querySnapshot = await getDocs(collection(db, "rooms"));
  const rooms = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data(),
  }));
  console.log("Rooms fetched: ", rooms);
  return rooms;
});

// Fetch room by creator account from firestore
export const fetchRoomsByCreatorId = createAsyncThunk(
  "rooms/fetchRoomsByCreatorId",
  async ({ userId }) => {
    try {
      const q = query(
        collection(db, "rooms"),
        where("creatorId", "==", userId),
      );
      const querySnapshot = await getDocs(q);
      let rooms = [];
      querySnapshot.forEach(async (doc) => {
        console.log(doc.id, "=>", doc.data());

        const room = {
          id: doc.id,
          data: doc.data(),
        };

        rooms.push(room);
      });

      return rooms;
    } catch (err) {
      console.log("Error fetching room by creator: ", err);
    }
  },
);

// Fetch room by room ID
export const fetchRoomById = createAsyncThunk(
  "rooms/fetchRoomById",
  async ({ id }) => {
    try {
      const docRef = doc(db, "rooms", `${id}`);
      const res = await getDoc(docRef);

      const room = {
        id: res.id,
        data: res.data(),
      };
      console.log("Fetched room: ", room);

      return room;
    } catch (err) {
      console.log("Error fetching room by id: ", err);
    }
  },
);

// Publish room
export const setPublishedStatus = createAsyncThunk(
  "rooms/setPublishedStatus",
  async ({ id, isPublishing }) => {
    try {
      console.log("updating docs");
      const roomRef = doc(db, "summary", id);
      await updateDoc(roomRef, { isPublished: isPublishing });
      return { roomId: id, status: isPublishing };
    } catch (err) {
      console.error("Error publish room: ", err);
    }
  },
);

const defaultBackground = backgroundLibrary[0].uri;
const defaultDoor = itemLibraryV2["Door"];

const initialState = {
  roomSettings: {
    background: defaultBackground,
    roomName: "Untitled",
    creator: "",
    timeLimit: 5,
    introduction: "",
    itemOrder: ["Door"],
    itemConfigs: {
      Door: {
        uri: defaultDoor.variants.brown.default,
        visible: true,
        isLocked: false,
        width: defaultDoor.width,
        position: { ...defaultDoor.defaultPosition },
        color: "brown",
        state: "default",
        isAddableToInventory: false,
        description: "",
        customDescription: "",
        itemToHide: "",
        isItemLocked: true,
        unlockMethod: "password",
        itemToUnlock: "",
        unlockPassword: "",
        isHidden: false,
      },
    },
    itemCounter: 0,
    isPreviewed: false,
    isPublished: false,
  },
  currentRoomId: "",
  error: false,
  roomArray: [],
  isSaving: false,
  activeEditingItemId: "Door",
  activeTab: "themes",
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    updateRoomName: (state, action) => {
      state.roomSettings.roomName = action.payload;
      console.log("New room name: ", state.roomSettings.roomName);
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSaving: (state, action) => {
      state.isSaving = action.payload;
    },
    // setLoading: (state, action) => {
    //   state.isLoaded = true;
    // },
    checkPreviewed: (state, action) => {
      state.roomSettings.isPreviewed = true;
    },
    setInitialRoomSettings: (state, action) => {
      return initialState;
    },
    setBackground: (state, action) => {
      const { background } = action.payload;
      state.roomSettings.background = background;
    },
    setDoor: (state, action) => {
      const { color, uri, width, position } = action.payload;
      state.roomSettings.itemConfigs.Door.color = color;
      state.roomSettings.itemConfigs.Door.uri = uri;
      state.roomSettings.itemConfigs.Door.width = width;
      state.roomSettings.itemConfigs.Door.position = position;

      console.log("Updating door selection");
    },
    clearRoomEditor: (state, action) => {
      console.log("resetting room");
      state = { ...initialState }
    },
    addItem: (state, action) => {
      const { id, info } = action.payload;
      state.roomSettings.itemCounter++;
      // console.log("Adding Items:", name, info);
      // const currentItemId = `${name}_${state.itemCounter}`;
      const currentItemId = `${id}`;

      // Items are added in acsending order of their z-index
      // The higher the index of the item, the higher their layer is
      state.roomSettings.itemOrder.push(currentItemId);
      state.roomSettings.itemConfigs = {
        ...state.roomSettings.itemConfigs,
        [currentItemId]: { ...info },
      };
      state.activeEditingItemId = currentItemId;

      console.log(
        "Adding items into current room",
        state.roomSettings.itemConfigs,
      );
    },
    deleteItem: (state, action) => {
      console.log("Delete Items ", action.payload);
      const itemId = action.payload;
      // Create a new itemConfigs object without the deleted item
      const { [itemId]: _, ...newItemConfigs } = state.roomSettings.itemConfigs;
      const index = state.roomSettings.itemOrder.indexOf(itemId);
      state.activeEditingItemId = state.roomSettings.itemOrder[index - 1]

      // If is selected in other items, remove from that relation

      console.log("New item config before: ", newItemConfigs)
      Object.keys(newItemConfigs).map((key) => {
        if (newItemConfigs[key].itemToHide === itemId) {
          // Reset itemToHide to an empty string
          newItemConfigs[key].itemToHide = "";
        }

        // Reset unlock method and itemToUnlock
        if (newItemConfigs[key].itemToUnlock === itemId) {
          newItemConfigs[key].itemToUnlock = "";
          newItemConfigs[key].unlockMethod = "password"
        }
      });
      console.log("New item config after: ", newItemConfigs)

      // Create a new itemOrder without the deleted item
      const newItemOrder = state.roomSettings.itemOrder.filter(item => item !== itemId);

      // Update state immutably
      state.roomSettings.itemConfigs = newItemConfigs;
      state.roomSettings.itemOrder = newItemOrder;

      console.log("AFter deleting: ", newItemOrder)

    },
    updateActiveEditingItem: (state, action) => {
      const { itemId } = action.payload;
      console.log("Updating activeEditing ITem Id, ", itemId);
      state.activeEditingItemId = itemId;
    },
    updateItemUri: (state, action) => {
      const { itemId, newState } = action.payload;
      const name = itemId.split("_")[0];
      const currentColor = state.roomSettings.itemConfigs[itemId].color;
      const newStateUri = itemLibraryV2[name].variants[currentColor][newState];
      state.roomSettings.itemConfigs[itemId].state = newState;
      console.log("new uri: ", newStateUri);
      if (newStateUri) {
        state.roomSettings.itemConfigs[itemId].uri = newStateUri;
      }
    },
    updateItemColor: (state, action) => {
      const { itemId, newColor } = action.payload;
      console.log("Updating item color: ", itemId, newColor);
      state.roomSettings.itemConfigs[itemId].color = newColor;
      const name = itemId.split("_")[0];
      const newUri = itemLibraryV2[name].variants[newColor].default;
      console.log("newUri", newUri);
      state.roomSettings.itemConfigs[itemId].uri = newUri;

      console.log(
        "New uri: ",
        state.roomSettings.itemConfigs[itemId].color,
        state.roomSettings.itemConfigs[itemId].uri,
      );
    },
    setVisibility: (state, action) => {
      console.log("Toggle visibility: ", action.payload);
      const { itemId, visible } = action.payload;
      state.roomSettings.itemConfigs[itemId].visible = visible;
    },
    setIsLocked: (state, action) => {
      console.log("Setting lock layer", action.payload);
      const { itemId, isLocked } = action.payload;
      state.roomSettings.itemConfigs[itemId].isLocked = isLocked;
    },

    updatePosition: (state, action) => {
      const { selected, newX, newY } = action.payload;
      // console.log(`Change ${selected} position to (${newX}, ${newY})`);
      state.roomSettings.itemConfigs[selected].position.x = newX;
      state.roomSettings.itemConfigs[selected].position.y = newY;
    },
    bringToFront: (state, action) => {
      // To bring the item to the front, remove it from its original position and add it at the end of the array.
      console.log(`Bringing ${action.payload} to the front`);
      const order = state.roomSettings.itemOrder;
      const index = order.indexOf(action.payload);
      if (index < order.length - 1) {
        const removed = order.splice(index, 1)[0];
        order.push(removed);
        state.roomSettings.itemOrder = order;
      }
    },
    bringForward: (state, action) => {
      // To bring forward a layer, swap it with the next item with the higher index
      const order = state.roomSettings.itemOrder;
      const index = order.indexOf(action.payload);
      console.log(order[index], order[index + 1]);

      if (index < order.length - 1) {
        [order[index], order[index + 1]] = [order[index + 1], order[index]];
        console.log(`Swapping ${order[index]} with ${order[index + 1]}`);
        state.roomSettings.itemOrder = order;
      }
    },
    bringBackward: (state, action) => {
      // To bring backward a layer, swap it with the previous ietm with a lower index
      const order = state.roomSettings.itemOrder;
      const index = order.indexOf(action.payload);
      if (index > 1) {
        [order[index], order[index - 1]] = [order[index - 1], order[index]];
        console.log(`Swapping ${order[index]} with ${order[index - 1]}`);
        state.roomSettings.itemOrder = order;
      }
    },
    bringToBack: (state, action) => {
      // To bring the item to the back, remove it from original poisition and insert it in the front
      const order = state.roomSettings.itemOrder;
      const itemId = action.payload;
      const index = order.indexOf(itemId);
      console.log(`Bringing ${action.payload} to the back`);
      if (index > 1) {
        const removed = order.splice(index, 1)[0];
        order.splice(0, 0, removed);
        state.roomSettings.itemOrder = order;
      }
    },
    updateRoomSettings: (state, action) => {
      state.roomSettings = { ...state.roomSettings, ...action.payload };
    },
    updateDescription: (state, action) => {
      const { itemId, description } = action.payload;
      state.roomSettings.itemConfigs[itemId].description = description;
      if (description !== "Custom Message") {
        state.roomSettings.itemConfigs[itemId].customDescription = "";
      }
    },
    updateCustomDescription: (state, action) => {
      const { itemId, customDescription } = action.payload;
      state.roomSettings.itemConfigs[itemId].customDescription =
        customDescription;
    },
    updateIsHidingAnotherItem: (state, action) => {
      const { itemId, isHidingAnotherItem } = action.payload;
      state.roomSettings.itemConfigs[itemId].isHidingAnotherItem =
        isHidingAnotherItem;

      const originalHiddenItem = state.roomSettings.itemConfigs[itemId].itemToHide;
      if (!isHidingAnotherItem) {
        state.roomSettings.itemConfigs[itemId].itemToHide = "";
        if (originalHiddenItem !== "") {
          state.roomSettings.itemConfigs[originalHiddenItem].isHidden = false;
        }
      }
    },
    updateItemToHide: (state, action) => {
      const { itemId, itemToHideId } = action.payload;
      const originalHiddenItem = state.roomSettings.itemConfigs[itemId].itemToHide;
      if (originalHiddenItem !== "") {
        state.roomSettings.itemConfigs[originalHiddenItem].isHidden = false;
      }
      if (itemToHideId) {
        state.roomSettings.itemConfigs[itemId].itemToHide = itemToHideId;
        state.roomSettings.itemConfigs[itemToHideId].isHidden = true;
        console.log(`Updated Items to hide by ${itemId}: ${itemToHideId}`);
      } else {
        state.roomSettings.itemConfigs[itemId].itemToHide = "";
        state.roomSettings.itemConfigs[itemToHideId].isHidden = false;
      }
    },
    updateUnlockMethod: (state, action) => {
      const { itemId, unlockMethod } = action.payload;
      state.roomSettings.itemConfigs[itemId].unlockMethod = unlockMethod;
      if (unlockMethod === "item") {
        state.roomSettings.itemConfigs[itemId].unlockPassword = "";
      } else if (unlockMethod === "password") {
        state.roomSettings.itemConfigs[itemId].itemToUnlock = "";
        const originalTool = state.roomSettings.itemConfigs[itemId].itemToUnlock;

        if (originalTool) {
          state.roomSettings.itemConfigs[originalTool].isAddableToInventory =
            false;
        }
      }
    },
    updateIsItemLocked: (state, action) => {
      const { itemId, isLocked } = action.payload;
      state.roomSettings.itemConfigs[itemId].isItemLocked = isLocked;
      if (!isLocked) {
        state.roomSettings.itemConfigs[itemId].itemToUnlock = "";
        state.roomSettings.itemConfigs[itemId].unlockPassword = "";
      }
      console.log(
        `Updated is item locked ${itemId}: ${state.roomSettings.itemConfigs[itemId].isItemLocked}`,
      );
    },
    updateUnlockPassword: (state, action) => {
      const { itemId, password } = action.payload;
      state.roomSettings.itemConfigs[itemId].itemToUnlock = "";
      state.roomSettings.itemConfigs[itemId].unlockPassword = password;
      console.log(`updating password for ${itemId}:`, password);
    },
    updateitemToUnlock: (state, action) => {
      const { itemId, itemToUnlockId } = action.payload;


      // reset
      // state.roomSettings.itemConfigs[itemId].unlockPassword = "";
      const originalTool = state.roomSettings.itemConfigs[itemId].itemToUnlock;

      if (originalTool) {
        state.roomSettings.itemConfigs[originalTool].isAddableToInventory =
          false;
      }

      if (itemToUnlockId) {
        state.roomSettings.itemConfigs[itemId].itemToUnlock = itemToUnlockId;
        state.roomSettings.itemConfigs[itemToUnlockId].isAddableToInventory =
          true;
        console.log(`updating item for ${itemId}:`, itemToUnlockId);
      } else {
        state.roomSettings.itemConfigs[itemId].itemToUnlock = "";
      }
    },
    updateAction: (state, action) => {
      const { itemId, directActionData } = action.payload;

      state.roomSettings.itemConfigs[itemId].onClick.direct = directActionData;

      console.log(
        `Updating Actions:`,
        state.roomSettings.itemConfigs[itemId].onClick.direct.payload.details,
      );
    },

    setActiveTab: (state, action) => {
      const { tab } = action.payload;
      state.activeTab = tab;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(addRoomToFirestore.fulfilled, (state, action) => {
        // state = { ...initialState }; //todo: should be resetting
        // console.log(action.payload)
        const { id, data } = action.payload;
        state.currentRoomId = id;
        console.log("current ID: ", state.currentRoomId);
      })
      .addCase(updateRoomInFirestore.fulfilled, (state, action) => {
        state = { ...initialState };
        console.log("Should be clear", state);
        state.currentRoomId = action.payload;
        return state;
      })
      .addCase(fetchRooms.fulfilled, (state, action) => {
        state.roomArray = action.payload;
      })
      .addCase(fetchRoomsByCreatorId.fulfilled, (state, action) => {
        const rooms = action.payload;
        state.roomArray = rooms;
      })
      .addCase(fetchRoomById.fulfilled, (state, action) => {
        const room = action.payload;
        state.roomSettings = { id: room.id, ...room.data };
      })

      .addCase(setPublishedStatus.fulfilled, (state, action) => {
        const { roomId, status } = action.payload;
        console.log(roomId, status)

        const room = state.roomArray.find((room) => room.id === roomId);
        if (room) {
          room.data.isPublished = status; // Update the status directly
        }
      });
  },
});

export const {
  updateRoomName,
  setError,
  setSaving,
  addItem,
  checkPreviewed,
  updateItemUri,
  updateItemColor,
  setVisibility,
  setIsLocked,
  deleteItem,
  updatePosition,
  bringToFront,
  bringForward,
  bringBackward,
  bringToBack,
  updateAction,
  setBackground,
  setDoor,
  setInitialRoomSettings,
  loadExistingRoom,
  updateRoomSettings,
  updateDescription,
  updateCustomDescription,
  updateIsHidingAnotherItem,
  updateItemToHide,
  updateIsItemLocked,
  updateUnlockMethod,
  updateUnlockPassword,
  updateitemToUnlock,
  updateActiveEditingItem,
  setActiveTab,
  clearRoomEditor,
} = editorSlice.actions;

export const saveSettings = (settingData) => async (dispatch) => {
  try {
    dispatch(setSaving(true));
    console.log("saving to firebase: todo");
    dispatch(setSaving(false));
  } catch (error) {
    // dispatch(setError({ error: true, errorMessage: error.message }));
    console.log("Error uploading to firebase");
  }
};

export const selectCurrentItems = (state) =>
  state.editor.roomSettings.itemConfigs;
export const selectedBackground = (state) =>
  state.editor.roomSettings.background;
export const selectedDoor = (state) =>
  state.editor.roomSettings.itemConfigs.Door.uri;

export const selectItemCount = (state) => state.editor.roomSettings.itemCounter;
export const selectRoomSettings = (state) => {
  return state.editor.roomSettings;
};

export const selectGeneralSettings = createSelector(
  [selectRoomSettings],
  (roomSettings) => {
    const { roomName, timeLimit, introduction, creator } = roomSettings;
    return { roomName, timeLimit, introduction, creator };
  },
);

export const selectTimelimit = (state) => state.editor.roomSettings.timeLimit;

export const selectLoadedRooms = (state) => state.editor.roomArray;
export const selectItemPositionAndURI = (state) =>
  Object.entries(state.editor.roomSettings.itemConfigsRedux).reduce(
    (result, [key, item]) => {
      result[key] = {
        left: item.position.x,
        top: item.position.y,
        uri: item.uri,
        width: item.width,
      };
      return result;
    },
    {},
  );

export const selectActiveEditingItem = (state) => state.editor.activeEditingItemId;


export default editorSlice.reducer;
