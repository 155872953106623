// hooks/useResponsive.js
import { useMediaQuery } from '@mui/material';

const useResponsive = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
  const isLargeScreen = useMediaQuery('(min-width:961px');

  return { isSmallScreen, isMediumScreen, isLargeScreen };
};

export default useResponsive;