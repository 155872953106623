// Get user information stored in localstorage

export const useGetUserInfo = () => {
    const authData = JSON.parse(localStorage.getItem("auth"));
    if ( authData ) {
        const { userId, name, isAuth } = authData;
        return { userId, name, isAuth};
    } else {
        return { userId:null, name:null, isAuth:false};

    }
}


