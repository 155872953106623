// This file contains the Redux slice for the dashboard feature, including the fetchRooms action.

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const fetchRooms = createAsyncThunk("dashboard/fetchRooms", async ({ itemsPerPage = 6, sorting = "Latest", lastVisible = null }, { getState }) => {
  try {
    console.log("last visible in fetch rooms: ", lastVisible)
    let sortBy = "";
    const order = "desc";
    switch (sorting) {
      case "Latest":
        sortBy = "createdAt";
        break;
      case "Most Popular":
        sortBy = "count";
        break;
      case "Highest Rating":
        sortBy = "avgRating";
        break;
      default:
        sortBy = "createdAt";
        break;
    }

    let url = `https://summary-c6njiypzca-uc.a.run.app?isPublished=true&sortBy=${sortBy}&order=${order}&limit=${itemsPerPage}`;


    if (lastVisible) {
      url += `&startAfter=${lastVisible}`;
    }

    console.log("url: ", url)

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const rooms = await response.json(); // Await the JSON conversion

    console.log("result from API: ", rooms, rooms.length)
    return {
      rooms,
      lastVisible: rooms.length > 0 ? rooms[rooms.length - 1].id : null,
      hasMore: rooms.length === itemsPerPage,
    }; // Return the last document for pagination
  } catch (err) {
    console.log("Error fetching ordered rooms: ", err);
    throw err; // Rethrow the error if necessary
  }
});





const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loadedRooms: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRooms.fulfilled, (state, action) => {
      // const {rooms} = action.payload
      // state.loadedRooms = [...state.loadedRooms, ...rooms];
      // console.log("last visible: ", state.lastVisible)

    });
  },
});



export const selectLoadedRooms = (state) => state.dashboard.loadedRooms;

export default dashboardSlice.reducer;
