import {
  Button, Typography,
  Tabs,
  Tab,
  Menu,
  styled,
} from "@mui/material";
import useResponsive from "../hooks/useResponsive";

export const StyledButtonGroup = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: "30px",
  gap: "15px",
  alignItems: "center",
  // width: "50%",
});

export const StyledButton = ({ text, onClick }) => {
  const { isSmallScreen } = useResponsive();
  return (
    <Button
      onClick={onClick}
      sx={{
        width: isSmallScreen ? "100px" : "140px",
        // height: "41.37px",
        backgroundColor: "rgba(0,0,0,0)",
        backgroundImage: `url('/images/elements/webp/button_shadow_off.webp')`,
        backgroundSize: "100%, 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        '&:hover': {
          backgroundColor: "rgba(0,0,0,0)", // Maintain the same background color
          backgroundImage: `url('/images/elements/webp/button_shadow_off.webp')`, // Maintain the same background image
          opacity: "0.8"
        },
      }}
    >
      <Typography fontSize={isSmallScreen ? "15px" : "20px"} fontFamily="Siller" color="text.primary">
        {text}
      </Typography>
    </Button>
  );
};

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTab-root.Mui-selected": {
    color: theme.palette.text.primary,
    "&:after": {
      content: '""',
      height: "2px",
      width: "60px",
      position: "absolute",
      left: 15,
      right: 0,
      bottom: 10,
      backgroundColor: theme.palette.text.primary,
    },
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: `${theme.palette.text.primary}80`,
  "&.Mui-selected": {
    color: theme.palette.text.primary,
  },
}));

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 0,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.text.primary,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
      backgroundColor: theme.palette.secondary.main,
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: theme.palette.primary.main,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));
