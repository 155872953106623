import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../config/firebase-config";
import {
  addDoc,
  getDocs,
  collection,
  query,
  where,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

// User bookmarking a room
export const bookmarkRoom = createAsyncThunk(
  "bookmarks/bookmarkRoom",
  async ({ userId, roomId }) => {
    try {
      console.log(userId, roomId);
      const docRef = doc(db, "bookmarks", `${userId}_${roomId}`);
      await setDoc(docRef, { userId, roomId });
    } catch (err) {
        console.log("Error bookmarking: ", err);
    }
}
);

// User unbookmarking a room 
export const removeBookmark = createAsyncThunk(
    "bookmarks/removebookmark",
    async ({ userId, roomId}) => {
        try {
            await deleteDoc(doc(db, "bookmarks", `${userId}_${roomId}`));
        } catch (err) {
            console.log("Error removing bookmark", err);
        }
    }
)

// // Fetch all bookmarked record
// export const fetchBookmarks = createAsyncThunk( 
//     "bookmarks/fetchBookmarks",
//     async () => {
//         try {
//             console.log("Fetching all records");
//             const querySnapshot  = await getDocs(collection(db, "bookmarks"));
//             const roomBookmarks = {};
//             querySnapshot.forEach((doc) => {
//                 const { userId, roomId} = doc.data();
//                 console.log("Before: ", roomBookmarks);
//                 if (roomBookmarks[roomId]) {
//                     roomBookmarks[roomId] = [...roomBookmarks[roomId], userId]
                    
//                 } else {
//                     roomBookmarks[roomId] = [userId];
//                 }
//                 console.log("After: ", roomBookmarks);
//             })
//             return roomBookmarks;

//         } catch (err) {
//             console.log("Error fetching bookmarked room:", err)
//         }
//     }
// )

// Fetch list of bookarked room by userID
export const fetchBookmarkedRoomByUserId = createAsyncThunk(
  "bookmarks/fetchBookmarkedRoomByUserId",
  async ({ userId }) => {
    try {
      const q = query(
        collection(db, "bookmarks"),
        where("userId", "==", userId)
      );
      const querySnapshot = await getDocs(q);
      let bookmarked = [];
      querySnapshot.forEach(async (doc) => {
        bookmarked.push(doc.data().roomId);
      });

      return bookmarked;
    } catch (err) {
      console.log("Error fetching bookmarked room: ", err);
    }
  }
);

// Fetch average ratings of all rooms
export const fetchAverageRatingsAndCount = createAsyncThunk(
  "ratings/fetchAverageRatings",
  async () => {
    try {
      const roomRatings = {};
      //   const docRef = doc(db, "ratings", `${roomId}`);
      const querySnapshot = await getDocs(collection(db, "ratings"));

      querySnapshot.forEach((doc) => {
        const roomId = doc.id;
        const { sum, count } = doc.data();
        roomRatings[roomId] = {
          average: sum / count,
          count: count,
        };
      });

      return roomRatings;
    } catch (err) {
      console.log("Error fetching room by room ID: ", err);
    }
  }
);

export const bookmarkSlice = createSlice({
  name: "bookmarks",
  initialState: {
    bookmarked: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bookmarkRoom.fulfilled, (state, action) => {
        console.log("Bookmarking room: ");
      })
    //   .addCase(fetchBookmarkedRoomByUserId.fulfilled, (state, action) => {
    //     state.bookmarked = action.payload;
    //     console.log("Fetching bookmarked rooms", state.bookmarked);
    //   })
    //   .addCase(fetchBookmarks.fulfilled, (state, action) => {
    //     console.log("FEtching all bookmarks", action.payload);
    //     state.bookmarked = action.payload;
    //   })
  },
});

export const {} = bookmarkSlice.actions;
export const selectBookmarkedRooms = (state) => state.bookmarks.bookmarked;

export default bookmarkSlice.reducer;
