import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import useResponsive from '../hooks/useResponsive';

// Create a Context
const WindowWidthContext = createContext();

export const useWindowWidth = () => {
    return useContext(WindowWidthContext);
};

const WindowWidthProvider = ({ children }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { isSmallScreen, isMediumScreen } = useResponsive();

    const defaultWidth = {
        xs: 95, // in percentage
        md: 90, // in percentage
        lg: 60,
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const containerDimensions = useMemo(() => {
        const width = (isSmallScreen ? defaultWidth.xs : isMediumScreen ? defaultWidth.md : defaultWidth.lg) / 100 * windowWidth;
        const height = width * (9 / 16);
        return {
            width: width,
            height: height,
        };
    }, [windowWidth, isSmallScreen, isMediumScreen, defaultWidth.xs, defaultWidth.sm]);

    return (
        <WindowWidthContext.Provider value={{ windowWidth, containerDimensions }}>
            {children}
        </WindowWidthContext.Provider>
    );
};

export default WindowWidthProvider;