import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../config/firebase-config";
import {
  doc,
  getDoc,
} from "firebase/firestore";


// Add room ratings to Firestore through API call.
// avgRating is a field in summary under the corresponding roomId. 
export const addRatingToFirestore = createAsyncThunk(
  "ratings/addRatingToFirestore",
  async ({ roomId, rating }) => {
    try {
      const newRating = { roomId, rating };

      const response = await fetch('https://postrating-c6njiypzca-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ roomId, rating })
      });

      if (!response.ok) {
        throw new Error('Failed to post rating');
      }

      const data = await response.json();
      console.log("Rating posted successfully:", data);


      return newRating;
    } catch (err) {
      console.log("Error uploading rating: ", err);
    }
  }
);

// Fetch average ratings by roomId
export const fetchAverageRatingById = createAsyncThunk(
  "ratings/fetchAverageRatingById",
  async ({ id }) => {
    try {
      console.log("rating by Room id");
      const docRef = doc(db, "summary", `${id}`);
      const res = await getDoc(docRef);
      // Check if the document exists
      if (!res.exists()) {
        console.log("No record found for Room ID:", id);
        return 0; // Return 0 if no record exists
      }
      const data = res.data();

      return data.avgRating;
    } catch (err) {
      console.log("Error fetching ratings by room ID: ", err);
    }
  }
);

export const ratingSlice = createSlice({
  name: "rating",
  initialState: {
    currentRating: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addRatingToFirestore.fulfilled, (state, action) => {
        console.log("Adding rating to firestore: ", action.payload);
      })
      .addCase(fetchAverageRatingById.fulfilled, (state, action) => {
        state.currentRating = action.payload;
      });
  },
});

export const { } = ratingSlice.actions;


export const selectCurrentRoomRating = (state) => state.rating.currentRating;

export default ratingSlice.reducer;
