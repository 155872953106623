// This file contains the Redux slice for the profile feature, including the fetchRooms action.

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs, deleteDoc, doc, collection, query, where, orderBy } from "firebase/firestore";
import {
  ref,
  deleteObject,
} from "firebase/storage";

import { db, storage } from "../../config/firebase-config";

// Fetch room by creator account from firestore
export const fetchRoomsByCreatorId = createAsyncThunk(
  "rooms/fetchRoomsByCreatorId",
  async ({ userId }) => {
    try {
      const q = query(
        collection(db, "summary"),
        where("creatorId", "==", userId),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      let rooms = [];
      querySnapshot.forEach(async (doc) => {
        console.log(doc.id, "=>", doc.data());

        const room = {
          id: doc.id,
          data: doc.data(),
        };

        rooms.push(room);
      });

      return rooms;
    } catch (err) {
      console.log("Error fetching room by creator: ", err);
    }
  },
);

// Delete room from firestore
export const deleteRoomFromFirestore = createAsyncThunk(
  "rooms/deleteRoomFromFirestore",
  async ({ roomId }) => {
    console.log(`Deleting room ${roomId}`);
    await deleteDoc(doc(db, "rooms", roomId));
    await deleteDoc(doc(db, "summary", roomId));

    const storageRef = ref(storage, `images/${roomId}.webp`);
    await deleteObject(storageRef);
  },
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    loadedRooms: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRoomsByCreatorId.fulfilled, (state, action) => {
      state.loadedRooms = action.payload;
    })
      .addCase(deleteRoomFromFirestore.fulfilled, (state, action) => {
        const deletedRoomId = action.meta.arg.roomId;
        state.loadedRooms = state.loadedRooms.filter(
          (room) => room.id !== deletedRoomId,
        );
      })

  },
});



export const selectLoadedRooms = (state) => state.profile.loadedRooms;

export default profileSlice.reducer;
