import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../config/firebase-config";
import {
  addDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";

// Add player record to Firestore
export const addRecordToFirestore = createAsyncThunk(
  "records/addRecordToFirestore",
  async ({ roomId, playerId, username, time }) => {
    try {
      const newRecord = { roomId, playerId, username, time };
      // Add new records to records collection
      const addRoomRef = await addDoc(collection(db, "records"), newRecord);

      console.log("Added new record: ", addRoomRef);
      return newRecord;
    } catch (err) {
      console.log("Error uploading pic: ", err);
    }
  }
);

// Fetch records by room ID
export const fetchRecordsByRoomId = createAsyncThunk(
    "records/fetchRecordsByRoomId",
    async ({ roomId }) => {
        try {
          const q = query(collection(db, "records"), where("roomId", "==", roomId));
          const querySnapshot = await getDocs(q);
          let records = [];
          querySnapshot.forEach(async (doc) => {
            console.log(doc.id, "=>", doc.data());
    
            const record = {
              id: doc.id,
              data: doc.data(),
            };
    
            records.push(record);
          });
    
          return records;
        } catch (err) {
          console.log("Error fetching room by room ID: ", err);
        }
      }
  );

export const recordSlice = createSlice({
  name: "gameRecord",
  initialState: {
    records: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addRecordToFirestore.fulfilled,  (state, action) => {
        console.log("Adding record to firestore: ", action.payload);
    })
    .addCase(fetchRecordsByRoomId.fulfilled, (state, action) => {
        state.records  = action.payload;
        console.log("List of records: ", state.records)
    });
  },
});

export const {} = recordSlice.actions;

export const selectRecords = (state) => state.gameRecord.records;


export default recordSlice.reducer;
