import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend';

// Utility function to check if the device is mobile
const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);
console.log("isMobile? ", isMobile());
const backend = isMobile()
  ? TouchBackend
  : HTML5Backend;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DndProvider backend={backend} options={{enableMouseEvents: isMobile()}}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </DndProvider>
);
