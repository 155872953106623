import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isStarted: false,
  isPaused: false,
  isGameOver: false,
  isEscaped: false,
}

export const gameStatusSlice = createSlice({
  name: "gameStatus",
  initialState,
  reducers: {
    startGame: (state, action) => {
      state.isStarted = true;
      state.isPaused = false;
    },
    pauseGame: (state, action) => {
      state.isPaused = true;
    },
    resumeGame: (state, action) => {
      state.isPaused = false;
    },
    gameOver: (state, action) => {
      state.isPaused = false;
      state.isGameOver = true;
    },
    successfulEscape: (state, action) => {
      console.log("Set isEscaped")
      state.isEscaped = true;
    },
    resetGameStatus: (state, action) => {
      console.log("Resetting game state");
      return initialState
    }
  },
});

export const { startGame, pauseGame, resumeGame, gameOver, resetGameStatus, successfulEscape } =
  gameStatusSlice.actions;
export const selectGameStatus = (state) => state.gameStatus;
export default gameStatusSlice.reducer;
